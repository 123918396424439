import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { useImageForData } from "../../hooks/useAllImages"

export default function BrandPanel({ title, description, linkPath, linkText, imgPath }) {
    return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="bg-gray-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
            <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
              <div className="lg:self-center">
                <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                  <span className="block">{title}</span>
                </h2>
                <p className="mt-4 text-base leading-6 text-white">
                  {description}
                </p>
                <Link
                  to={linkPath}
                  className="mt-8 bg-amber-500 border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-white"
                >
                  {linkText}
                </Link>
              </div>
            </div>
            <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1" style={{ padding: 0 }}>
                <GatsbyImage
                    image={useImageForData(imgPath)}
                    className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                    alt={imgPath}
                />
            </div>
          </div>
        </div>
      </div>
    )
  }
  
