import React from "react";

const FAQsSection = ({ title, children }) => (
  <div className="bg-gray-50">
    <div className="max-w-7xl mx-auto py-12 px-4 divide-y divide-gray-200 sm:px-6 lg:py-16 lg:px-8">
      <h2 className="text-3xl font-extrabold text-gray-900 text-center">{title}</h2>
      <div className="mt-8">
        <dl className="divide-y divide-gray-200">{children}</dl>
      </div>
    </div>
  </div>
);

export default FAQsSection;
