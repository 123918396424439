import React, { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { CheckIcon } from "@heroicons/react/solid";
import { initialFormFields } from "../utils/utils";
import { bearerToken, captchaInfoUrl, sendMailerUrl } from "../../api/mailer";

const GetInTouchSection = ({
  title,
  description,
  subjectMail = "Request from short contact",
  submitButton,
  submitButtonSuccess,
  alertTitle = "Thank you!",
  alertText = "We`ve received your message. Someone from our team will contact you soon",
}) => {
  const recaptchaRef = useRef();

  const [formFields, setFormFields] = useState(initialFormFields);
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const [formIsSent, setFormIsSent] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const [siteKey, setSiteKey] = useState("");

  const getCaptchaInfo = async () => {
    const resp = await fetch(captchaInfoUrl, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken,
      },
    }).catch((err) => {
      new Error(err);
    });

    const data = await resp.json();

    setSiteKey(data.site_key);
  };

  useEffect(() => {
    getCaptchaInfo();
  }, []);

  const setPhone = (event) => {
    setFormFields({ ...formFields, phone: event.target.value });
  };
  const setEmail = (event) => {
    setFormFields({ ...formFields, email: event.target.value });
  };
  const setMessage = (event) => {
    setFormFields({ ...formFields, message: event.target.value });
  };

  const onSubmit = async () => {
    const response = await fetch(sendMailerUrl, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken,
      },
      body: JSON.stringify({
        ...formFields,
        subject: subjectMail,
        recaptcha: recaptchaValue,
      }),
    });
    setFormIsSent(true);

    if (response.status !== 200) {
      setIsError(true);
    } else {
      setFormFields(initialFormFields);
    }
  };

  return (
    <div className="relative bg-white my-16 sm:my-20">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 " />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              {title}
            </h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              {description}
            </p>
          </div>
        </div>
        <div className="bg-white py-0 lg:pb-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            {(formIsSent || isError) && (
              <div
                className={`rounded-md p-4 mb-6 ${
                  isError ? "bg-red-50" : "bg-green-50"
                }`}
              >
                <div className="flex">
                  <div className="ml-3">
                    <h3
                      className={`text-sm font-medium ${
                        isError ? "text-red-800" : "text-green-800"
                      }`}
                    >
                      {isError ? "Error!" : alertTitle}
                    </h3>
                    <div
                      className={`mt-2 text-sm ${
                        isError ? "text-red-700" : "text-green-700"
                      }`}
                    >
                      <p>{isError ? "Please, try again" : alertText}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <form className="grid grid-cols-1 gap-y-6">
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  onChange={setEmail}
                  className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-amber-500 focus:ring-white block w-full shadow-sm py-3 px-4 placeholder-gray-500 border-gray-300 rounded-md"
                  placeholder="Email *"
                />
              </div>
              <div>
                <label htmlFor="phone" className="sr-only">
                  Phone
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  autoComplete="tel"
                  required
                  onChange={setPhone}
                  className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-amber-500 focus:ring-white block w-full shadow-sm py-3 px-4 placeholder-gray-500 border-gray-300 rounded-md"
                  placeholder="Phone *"
                />
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  required
                  onChange={setMessage}
                  className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-amber-500 focus:ring-white block w-full shadow-sm py-3 px-4 placeholder-gray-500 border border-gray-300 rounded-md"
                  placeholder="Message *"
                  defaultValue={""}
                />
              </div>
              <div>
                {formFields.email &&
                  formFields.phone &&
                  formFields.message &&
                  !isVerified && (
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      badge={"inline"}
                      size={"compact"}
                      sitekey={siteKey}
                      onErrored={() => setIsVerified(false)}
                      onExpired={() => setIsVerified(false)}
                      onChange={(value) => {
                        setRecaptchaValue(value);
                        setIsVerified(true);
                      }}
                    />
                  )}

                {!formIsSent || isError ? (
                  <button
                    type="button"
                    onClick={onSubmit}
                    disabled={
                      !formFields.email ||
                      !formFields.phone ||
                      !formFields.message
                    }
                    className="inline-flex justify-center py-3 px-4 rounded-md shadow bg-amber-500 text-white font-medium hover:bg-amber-600 focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-amber-700 focus:ring-offset-gray-900 disabled:bg-gray-200"
                  >
                    {submitButton}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="inline-flex justify-center py-3 px-4 rounded-md shadow font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-green-600 hover:bg-green-700 focus:ring-green-500"
                  >
                    {submitButtonSuccess}
                    <CheckIcon className="h-6 w-6 ml-2 " />
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouchSection;
