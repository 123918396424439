import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const backgroundImagePath = "../../images/srv4pos_cash_register.png";

const ActionCallSection = ({
  mainTitle,
  highlightedText,
  description,
  children,
}) => (
  <div>
    <div className="bg-slate-700 lg:overflow-hidden">
      <div className="mx-auto overflow-hidden">
        <div className="relative">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-slate-700/[0.8]" />
          <div className="mx-auto">
            <div className="relative shadow-xl sm:overflow-hidden">
              <div className="absolute inset-0">
                {/* Background image */}
                <StaticImage
                  src={backgroundImagePath}
                  alt=""
                  className="w-full h-full object-cover"
                  imgClassName="w-full h-full object-cover"
                  height={600}
                  placeholder="none"
                />
                <div className="absolute inset-0 bg-slate-900/[0.6]" />
              </div>
              <div className="grid sm:grid-cols-2 relative px-4 py-16 sm:px-6 sm:py-24">
                <div></div>
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6">
                  <div className="lg:py-12">
                    <h1 className="grid mt-4 tracking-tight font-extrabold sm:mt-5 lg:mt-6">
                      {/* Main title */}
                      <span className="inline text-white text-4xl sm:text-5xl xl:text-6xl uppercase">
                        {mainTitle}
                      </span>
                      <span className="inline text-amber-500 text-4xl sm:text-5xl xl:text-6xl uppercase">
                        {highlightedText}
                      </span>
                    </h1>
                    {/* Description */}
                    <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                      {description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-blue-gray-50">
      <section className="sm:-mt-16 mb-8 z-10 relative px-6 lg:px-8 max-w-screen-2xl mx-auto">
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-4 lg:gap-y-0 lg:gap-x-8">
          {children}
        </div>
      </section>
    </div>
  </div>
);
export default ActionCallSection;
