import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
const image = "../../images/terminal-stock.jpg";

const FiscalizationSection = ({title, header, text}) => {
    return (
        <div className="relative bg-gray-700">
        <div className="h-56 bg-gray-700 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
            <StaticImage
                src={image}
                alt=""
                className="w-full h-full object-cover"
                imgClassName="w-full h-full object-cover"
                height={600}
                placeholder="none"
            />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
            <div className="md:ml-auto md:w-1/2 md:pl-10">
            <h2 className="text-base font-semibold uppercase tracking-wider bg-gradient-to-r from-amber-500 to-amber-300 bg-clip-text text-transparent">{title}</h2>
            <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">{header}</p>
            <p className="mt-3 text-lg text-gray-300">{text}</p>
            <div className="mt-8">
                <div className="inline-flex rounded-md shadow">
                <Link to="/fiscalization-api/" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-amber-500 hover:bg-amber-300">
                    Go to webpage
                    <svg className="-mr-1 ml-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                    <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                    </svg>
                </Link>
                </div>
            </div>
            </div>
        </div>
        </div>
    )
}

export default FiscalizationSection
