import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useImageForData } from "../../hooks/useAllImages";

const LogoItem = ({ title, imgPath }) => (
  <div className="flex flex-col justify-center">
    <GatsbyImage
      image={useImageForData(imgPath)}
      alt={title}
    />
  </div>
);

export default LogoItem;
