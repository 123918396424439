import { Link } from "gatsby";
import React from "react";

const ActionCallItem = ({ title, description, Icon, linkText, linkPath }) => (
  <div key={title} className="flex flex-col bg-white rounded-2xl shadow-xl">
    <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
      <div className="absolute top-0 p-5 inline-block bg-amber-500 rounded-xl shadow-lg transform -translate-y-1/2">
        <Icon className="h-6 w-6 text-white" aria-hidden="true" />
      </div>
      <h3 className="text-xl font-medium text-blue-gray-900">{title}</h3>
      <p className="mt-4 text-base text-blue-gray-500">{description}</p>
    </div>
    <div className="p-6 bg-blue-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
      <Link
        to={linkPath}
        className="text-lg font-medium text-amber-500 hover:text-amber-300"
      >
        {linkText}
        <span aria-hidden="true"> &rarr;</span>
      </Link>
    </div>
  </div>
);

export default ActionCallItem;
