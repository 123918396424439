import * as React from "react";
import { DesktopComputerIcon, UserIcon, CursorClickIcon, LightningBoltIcon, CubeTransparentIcon, CogIcon, ServerIcon } from "@heroicons/react/solid";

import PageTitle from "../components/elements/PageTitle";
import Header from "../components/sections/Header";
import ActionCallSection from "../components/sections/ActionCallSection";
import ActionCallItem from "../components/elements/ActionCallItem";
import Footer from "../components/sections/Footer";
import GetInTouchSection from "../components/sections/GetInTouchSection";
import LogoSection from "../components/sections/LogoSection";
import LogoItem from "../components/elements/LogoItem";
import StatsSection from "../components/sections/StatsSection";
import StatsItem from "../components/elements/StatsItem";
import FAQsSection from "../components/sections/FAQsSection";
import FAQsItem from "../components/elements/FAQsItem";
import ServicesSection from "../components/sections/ServicesSection"
import ServiceItem from "../components/elements/ServiceItem"
import FiscalizationSection from "../components/sections/FiscalizationSection";
// import BidFeaturesItem from "../components/elements/BidFeaturesItem";
import BrandPanel from "../components/elements/BrandPanel";
import MarketingFeaturesItem from "../components/elements/MarketingFeaturesItem";

const indexPage = () => (
  <div className="bg-white">
    <PageTitle pageTitle={"Home"} description={``} />
    <Header />
    <ActionCallSection
      mainTitle={"CLOUD"}
      highlightedText={"CONTROL UNIT"}
      description={
        "Unlock the full potential of your POS software with CCU-Service integration by following these steps:"
      }
    >
      <ActionCallItem
        title="Step 1: Create Your Developer Account"
        description="Sign up for a developer account and provide necessary information about your company and software."
        Icon={UserIcon}
        linkText="Know more"
        linkPath="/ccu/"
      />
      <ActionCallItem
        title="Step 2: Explore the Test Environment"
        description="Dive into the test environment to experiment with integration options, test demos, and access API documentation."
        Icon={DesktopComputerIcon}
        linkText="Know more"
        linkPath="/ccu/"
      />
      <ActionCallItem
        title="Step 3: Streamline Tax Agency Registration"
        description="Simplify the process of registering your software with the Tax Agency by following clear instructions provided by CCU-Service."
        Icon={CursorClickIcon}
        linkText="Know more"
        linkPath="/ccu/"
      />
      <ActionCallItem
        title="Step 4: Power Your Production Environment"
        description="Once registered and compliant, unleash the full potential of CCU-Service in your real-world environment with ongoing support from the CCU-Service team."
        Icon={LightningBoltIcon}
        linkText="Know more"
        linkPath="/ccu/"
      />                  
    </ActionCallSection>

    
    <LogoSection title="TRUSTED BY">
      <LogoItem title="ancon" imgPath="integrators/Ancon.png" />
      <LogoItem title="voady" imgPath="integrators/_voady_pink_logo.png" />
      <LogoItem title="baemingo" imgPath="integrators/Baemingo.png" />
      <LogoItem title="weiq" imgPath="integrators/weiq-logo-1.png" />
      <LogoItem title="enklassan" imgPath="integrators/Enklakassan_.png" />
      <LogoItem title="retailforce" imgPath="integrators/RetailForce_.png" />
      
    </LogoSection>
    
    <StatsSection 
      subTitle="PERFORMANCE" 
      title="Unlock Peak Performance with our CCU Service" 
      description="The Cloud Control Unit service acts as a central fiscal data management system for businesses that use cash registers. It securely records and stores all transaction data, ensuring compliance with local tax regulations and facilitating easy access for auditing and reporting purposes." 
      imgPath="stats_image.jpg"
      btnDescription="Get Oracle app"
      btnText="Get Oracle app"
      btnPath="/"
    >
      <StatsItem stat="<98%" emphasis="transactions" rest="are proceeded less than 1 sec" />
      <StatsItem stat=">20 000" emphasis="requests per hour" rest="are proceeeding via our server" />
      <StatsItem stat=">500" emphasis="merchants" rest="are integrated and keep their data on srv4pos" />
      <StatsItem stat=">4000 " emphasis="POS" rest="are connected and send data" />
    </StatsSection>
   <ServicesSection
      sectionTitle={"Features"}
      title={"Key points of using CCU"}
      description={`These features make the CCU an integral part of modern POS systems, especially in regions with stringent fiscal regulations.`}
      showMoreText={"Know more"}
      showMorePath={"/ccu/"}
    >
      <ServiceItem
        name="Fiscal Data Recording and Management"
        description={`Recording transactions to ensure they meet legal standards for fiscal reporting, handling detailed transaction data like sales amounts, VAT rates, and receipt information`}
        IconImg={CubeTransparentIcon}
      />
      <ServiceItem
        name="API Integration"
        description={`Set of API endpoints for integrating with cash register systems. These APIs allow for the creation, activation, and management of activation keys`}
        IconImg={CogIcon}
      />
      <ServiceItem
        name="Centralized Control"
        description={`CCU can be connected to a central management system that oversees multiple POS installations, providing a centralized point of control for fiscal data from several cash register locations`}
        IconImg={CubeTransparentIcon}
      />
      <ServiceItem
        name="Security and Authorization"
        description={`The CCU utilizes Basic authentication and API keys for secure communication between cash registers and the control unit - all transaction data is transmitted securely and remains confidential`}
        IconImg={ServerIcon}
      />
      <ServiceItem
        name="Detailed Transaction Logging"
        description={`Every transaction processed through the CCU is logged in detail, that includes the time of transaction, receipt numbers, and detailed tax calculations`}
        IconImg={CogIcon}
      />
      <ServiceItem
        name="Real-Time Data Access and Reporting"
        description={`The CCU ensures that transaction data is accessible in real-time, enabling immediate viewing and reporting`}
        IconImg={CogIcon}
      />
    </ServicesSection>

    <FiscalizationSection
      header="Fiscalization"
      title="Alternative"
      text="If your POS installation case does not support Cloud Control Unit solution, we are ready to provide you alternative way to keep your fiscal data - fiscalization API. Click below to know more about it:" 
    />

    <FAQsSection title="FAQ">
      <FAQsItem question="What happens if CCU is broken?" answer="The HTTP request to send data to the CU is identified by the APIkey, which means you don't have to specify CU serial when sending the data. It means srv4pos will change broken CU to a new replaced version without any efforts from your side." />
      <FAQsItem question="Should I tell something to TaxAgency if CU was replaced?" answer="Nothing. We take care about everything ourselves." />
      <FAQsItem question="What happens if I request for APIkey, but the requested CCU is broken?" answer="The CU will be switched automatically to the new replacement." />
      <FAQsItem question="How do I know that CU has been replaced?" answer="We will inform you by email. You can change it in your server configuration within few days, both sending transactions API and requesting an APIkey API will keep working even with old CU serial (in fact new one will be used)." />
      <FAQsItem question="What happens if the CU host machine is dead, or hosting company is unavailable?" answer="We have a spare data center, but assuming you use single IP/domain name right now, we will come to you with spare IP that you can take over in case of damage." />
      <FAQsItem question="Is it possible to ping or check CCU?" answer="We do this ping internally ourselves but this API is closed for your access." />
    </FAQsSection>

    <MarketingFeaturesItem
      title="Advanced Control System for Fiscal Data Storage"
      description="Sweden's fiscal data management has advanced with the new Control System (CS), replacing traditional hardware-based Control Units (CU). This modern system streamlines data storage, eliminating the physical limitations of older technology"
      features={[
        {
          name: '3 key elements',
          description: 'Control Server - main storage for fiscal data, housed on a certified server. Control Program - software that can be installed on client, manufacturer or reseller side and that can buffer fiscal data for up to 48 hours. Signing Module - ensures data security and enables digital signing.',
        },
        {
          name: 'Option 1: Centralized Hosting',
          description: 'All three components are hosted by Axena, mirroring the existing CCU system with minimal benefits over the legacy solution. However, it simplifies the transition by enabling immediate integration with the existing REST HTTP API, requiring minimal changes for the switch to the Control System.',
        },
       {
          name: 'Option 2: Decentralized Hosting',
          description: 'This option places the Control Program and Signing Module near the Cash Register, managed by the reseller or manufacturer, allowing data buffering for continuous operation if connection is lost. It will be ready in nine months and uses gRPC over TLS for secure communication.',
        },        
        {
          name: 'Future-Proofing with Buffering Units',
          description: 'Integrating your Cash Registers using gRPC offers additional advantages, such as compliance with Buffering Units. These small, USB-powered, WiFi-enabled devices host both the Contril Program and Signing Module, making them ideal for in-store installations.',
        },
      ]}
    />

    <BrandPanel 
      title="Pleased to note"
      description="Our Cloud Control Unit was essential during Taylor Swift's concerts at Friends Arena on May 17-19, managing immense fiscal data from over 178,000 visitors. It ensured seamless operation without outages, showcasing our service's reliability and scalability for high-traffic events."
      linkPath="/contacts"
      linkText="Contact us"
      imgPath="taylor.png"
    />
    
    <GetInTouchSection
      title={"Get in touch with us"}
      description={`Send us a message to learn more about our services and pricing`}
      subjectMail="Request from short contact"
      submitButton={"Submit"}
      submitButtonSuccess={"Sent"}
      alertTitle="Thank you!"
      alertText="We`ve received your message. Support team will contact you soon"
    />
    <Footer />
  </div>
);

export default indexPage;
