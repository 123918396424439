import React from "react";

const FAQsItem = ({ question, answer }) => (
  <div key={question} className="pt-6 pb-8 md:grid md:grid-cols-12 md:gap-8">
    <dt className="text-base font-medium text-gray-900 md:col-span-5">
      {question}
    </dt>
    <dd className="mt-2 md:mt-0 md:col-span-7">
      <p className="text-base text-gray-500">{answer}</p>
    </dd>
  </div>
);

export default FAQsItem;
