import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useImageForData } from "../../hooks/useAllImages";
import { Link } from "gatsby";

const StatsSection = ({
  subTitle,
  title,
  description,
  imgPath,
  btnDescription,
  btnText,
  btnPath,
  children,
}) => (
  <div className="relative bg-gray-700">
    <div className="h-80 absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
      <div className="h-full w-full xl:grid xl:grid-cols-2">
        <div className="h-full xl:relative xl:col-start-2">
          <GatsbyImage
            image={useImageForData(imgPath)}
            className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
            imgClassName="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
            alt={title}
          />
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-700 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
          />
        </div>
      </div>
    </div>
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
      <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
        <h2 className="text-base font-semibold tracking-wide uppercase">
          <span className="bg-gradient-to-r from-amber-500 to-amber-300 bg-clip-text text-transparent">
            {subTitle}
          </span>
        </h2>
        <p className="mt-3 text-3xl font-extrabold text-white">{title}</p>
        <p className="mt-5 text-lg text-gray-300">{description}</p>
        <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
          {children}
        </div>
        <div className="mt-12">
          <p className="mt-5 text-lg text-white">{btnDescription}</p>
          {btnText && (
            <div className="mt-5">
              <div className="inline-flex rounded-md shadow">
                <Link
                  to={btnPath}
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-amber-500 hover:bg-amber-300"
                >
                  {btnText}
                  <svg
                    className="-mr-1 ml-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                    <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                  </svg>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default StatsSection;
